<template>
  <div>
    <ok-table
      ref="datatable"
      :config="config"
      :title="$t('store.categories')"
      @edit="openProcessModal"
      @delete="handleDelete"
    >
      <template slot="buttons">
        <b-button
          size="md"
          variant="dark"
          class="mx-md-1"
          @click="openProcessModal"
        >
          {{ $t('store.add-category') }}
        </b-button>
        <b-button
          size="md"
          variant="outline-warning"
          @click="showExcel=true"
        >
          {{ $t('excel.import') }}
        </b-button>
      </template>
      <template
        slot="title-column"
        slot-scope="{props}"
      >
        <b-badge
          variant="primary"
        >
          {{ getTitle(props.row) }}
        </b-badge>

      </template>
      <template
        v-slot:status-column="{props}"
      >
        <b-form-checkbox
          switch
          :unchecked-value="false"
          :checked="props.row.status"
          :value="true"
          @input="updateStatus($event,'status',props.row.id)"
        />

      </template>
    </ok-table>
    <excel-import-modal
      v-model="showExcel"
      :type="isSubcategroy?'subcategories':'categories'"
    />
    <process-category-modal
      v-model="showProcessModal"
      :category="selectedCategory"
      :is-subcategroy="isSubcategroy"
      @updated="refreshTable"
    />
  </div>
</template>

<script>
import { BButton, BBadge, BFormCheckbox } from 'bootstrap-vue'
import OkTable from '@/@bya3/components/tables/ok-table/OkTable'
import CategoryAPIs from '@/api/store/category'
import LocalizationService from '@/services/localization-service'
import ExcelImportModal from '@/views/store/ExcelImportModal'
import ProcessCategoryModal from '@/views/store/categories/components/ProcessCategoryPage'

export default {
  components: {
    ProcessCategoryModal,
    ExcelImportModal,
    OkTable,
    BButton,
    BBadge,
    BFormCheckbox,
  },
  props: {
    isSubcategroy: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showProcessModal: false,
      selectedCategory: {},
      showExcel: false,
    }
  },
  methods: {
    getTitle(category) {
      return LocalizationService.getTranslate(category)
    },
    refreshTable() {
      this.showProcessModal = false
      this.selectedCategory = {}
      this.$refs.datatable.loadItems()
    },
    openProcessModal(data) {
      this.showProcessModal = true
      this.selectedCategory = data?.row ?? null
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => CategoryAPIs.delete(data.row.id)
          .then(response => {
            this.$notifySuccess(response.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
    updateStatus(value, column, id) {
      const data = { id }
      data[column] = value
      CategoryAPIs.process(data)
        .then(response => {
          this.$notifySuccess(response.message)
          this.refreshTable()
        })
        .catch(e => this.$notifyError(e))
    },
  },
}
</script>
