<template>
  <process-category-page :is-subcategroy="true" />
</template>

<script>
import ProcessCategoryPage from '@/views/store/categories/components/ProcessCategoryPage'

export default {
  name: 'ProcessChildPage',
  components: { ProcessCategoryPage },
}
</script>
