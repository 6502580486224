<template>
  <div class="item-localization-form mt-5">
    <div class="item-localization-form-content">
      <b-row>
        <b-col
          v-for="locale in locales"
          :key="locale.locale"
          md="6"
          class="mb-1"
        >
          <ok-text-input
            v-model="locale.name"
            size="md"
            :label="`${$t('store.item-name')} (${$t(`locales.${locale.locale}`)})`"
            textarea
          />
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'

export default {
  name: 'CategoryLocalizationForm',
  components: {
    OkTextInput,
    BRow,
    BCol,
  },
  props: {
    isSubcategroy: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      locales: [],
    }
  },
  watch: {
    category() {
      this.setCategoryLocales()
    },
  },
  mounted() {
    this.setCategoryLocales()
  },
  methods: {
    prepareForSubmit() {
      return this.locales
    },
    setCategoryLocales() {
      const itemLocales = []
      this.userLocalesWithoutCurrent.forEach(loc => {
        if (this.category?.locales) {
          const index = this.category.locales.findIndex(l => l.locale === loc)
          if (index > -1) {
            itemLocales.push(this.category.locales[index])
          } else {
            itemLocales.push({ locale: loc, name: '', description: '' })
          }
        } else {
          itemLocales.push({ locale: loc, name: '', description: '' })
        }
      })
      this.locales = [...itemLocales]
    },
  },

}
</script>
