<template>
  <process-category-page />
</template>

<script>
import ProcessCategoryPage from '@/views/store/categories/components/ProcessCategoryPage'

export default {
  name: 'ProcessParentPage',
  components: { ProcessCategoryPage },
}
</script>
