import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import CommonTableActions from '@/constants/common-table-actions'

const CategoriesTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/categories`,
  rowsPath: 'categories',
  rowClickable: true,
  attachPageId: false,
  columns: [
    {
      label: 'fields.name',
      field: 'title',
    },
    {
      label: 'general.status',
      field: 'status',
    },
    {
      label: 'help.order',
      field: 'sort',
    },
    {
      label: 'fields.created-at',
      field: 'created_at',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
    ...CommonTableActions,
  ],
  filters: [
  ],
}

export default CategoriesTableConfig
