<template>
  <ok-modal
    :value="value"
    size="lg"
    dialog-class="list-modal"
    :title="$t(isEdit?'forms.edit':'forms.add')"
    @change="submitting ? null : $emit('input', $event)"
  >
    <div>
      <validation-observer ref="form">

        <b-tabs
          v-model="tab"
          class="mt-3"
          justified
          pills
        >
          <b-tab
            :title="$t('store.general')"
            :title-link-class="isGeneralValid ? '' : 'bg-danger text-white'"
          >
            <category-general-form
              ref="general"
              :form="form"
              :active="tab === 0"
              :is-subcategroy="isSubcategroy"
            />
          </b-tab>
          <b-tab
            v-if="userLocalesWithoutCurrent.length>0"
            :title="$t('general.languages')"
          >
            <category-localization-form
              ref="languages"
              :category="form"
              :active="tab === 1"
              :is-subcategroy="isSubcategroy"
            />
          </b-tab>
        </b-tabs>
      </validation-observer>
    </div>
    <template #actions>
      <div
        class="d-flex align-items-center"
      >
        <b-button
          variant="link"
          :disabled="submitting"
          @click="handleClose"
        >
          <span class="text-dark">{{ $t('forms.back') }}</span>
        </b-button>
        <b-button
          variant="dark"
          :disabled="submitting"
          @click="submit()"
        >
          <span :class="{'pr-1': submitting}">{{ $t('forms.save') }}</span>
          <b-spinner
            v-if="submitting"
            small
          />
        </b-button>
      </div>
    </template>
  </ok-modal>
</template>

<script>
import {
  BButton, BTabs, BTab, BSpinner,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import { ValidationObserver } from 'vee-validate'
import ErrorHandler from '@/utils/error-handler'
import CategoryGeneralForm from '@/views/store/categories/components/CategoryGeneralForm'
import CategoryLocalizationForm from '@/views/store/categories/components/CategoryLocalizationForm'
import CategoryAPIs from '@/api/store/category'
import LocalizationService from '@/services/localization-service'
import OkModal from '@/@bya3/components/modals/OkModal'

export default {
  name: 'ProcessCategoryModal',
  components: {
    OkModal,
    CategoryLocalizationForm,
    CategoryGeneralForm,
    ValidationObserver,
    BButton,
    BTabs,
    BTab,
    BSpinner,
  },
  props: {
    isSubcategroy: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: { },
      submitting: false,
      tab: 0,
      isGeneralValid: true,
      error: null,
    }
  },
  computed: {
    isEdit() {
      return !!this.category?.id
    },
  },
  watch: {
    category() {
      this.init()
    },
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      this.error = null
      if (this.isSubcategroy) {
        await this.fetchCategories()
      }
      if (!this.isEdit) {
        this.form = {}
        return
      }
      this.isGeneralValid = true

      this.form = this?.category ? cloneDeep(this.category) : {}
      this.form.description = LocalizationService.getTranslate(this.form, 'description', this.$i18n.locale, true)
      this.form.name = LocalizationService.getTranslate(this.form, 'name', this.$i18n.locale, true)
      this.form.title = LocalizationService.getTranslate(this.form, 'name')
      this.form.base_description = LocalizationService.getTranslate(this.form, 'description')
      this.form.is_category = true
      this.form.visible = !!this.form.visible
      this.form.media = this.form.image ? [this.form.image] : []
      this.form.available = this.form.available ?? { status: false, from: '', to: '' }
    },
    async submit() {
      this.$refs.general.setLocales()
      await new Promise(resolve => setTimeout(resolve, 200))
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.isGeneralValid = await this.$refs.general.isValid()
        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
        return
      }

      this.isGeneralValid = true

      const general = this.$refs.general.prepareForSubmit()
      let locales = [...general.locales]
      if (this.userLocalesWithoutCurrent.length > 0) locales = [...locales, ...this.$refs.languages.prepareForSubmit()]
      general.locales = JSON.stringify(locales)
      if (this.isEdit) general.id = this.category?.id
      this.submitting = true
      CategoryAPIs.process(general)
        .then(async response => {
          this.$notifySuccess(response.message)
          this.$store.commit('shop/SET_CATEGORIES_FETCHED', false)
          this.$emit('updated', true)
          return true
        })
        .catch(error => {
          this.error = error
        })
        .finally(() => { this.submitting = false })
    },
    handleClose() {
      this.$emit('input', false)
    },
    async fetchCategories() {
      await this.$store.dispatch('shop/FETCH_CATEGORIES')
        .catch(e => { console.log(e) })
    },
  },
}
</script>
<style lang="scss">

.nav{
  &.nav-pills.nav-justified .nav-link{
    border-radius: 0;
  }
  &.nav-pills.nav-justified .nav-link:not(.active){
    background-color: #D8D6DD;
    border-left: 1px solid #ffffff;
  }
}
</style>
