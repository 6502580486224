<template>
  <validation-observer
    ref="form"
    tag="div"
  >
    <div class="category-general-form mt-2">
      <div>
        <b-row class="align-items-center">
          <template v-if="isSubcategroy">
            <b-col
              md="6"
              class="mb-1"
            >
              <parent-category-select
                v-model="category.parent_id"
                :allow-root="false"
                size="md"
                show-hint
                :no-subcategories="true"
                :max-level="1"
                :excluded-id="category.id"
                :placeholder="!category.parent_id ? 'store.select-category' : 'store.select-parent-category'"
                :required="false"
              />
            </b-col>
            <b-col md="6" />
          </template>

          <b-col
            md="6"
          >
            <ok-text-input
              v-model="name"
              size="md"
              :label="`${$t('store.category-name')} (${$t(`locales.${selectedLocale}`)})`"
              is-required
            />
          </b-col>
          <b-col md="6">
            <ok-text-input
              v-model="category.sort"
              is-required
              rules="positive"
              :label="$t('help.order')"
            />
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-form-checkbox
              v-model="category.status"
              switch
            >
              {{ $t('store.visible') }}
            </b-form-checkbox>

          </b-col>
          <b-col
            v-if="!isSubcategroy"
            md="12"
          >
            <my-menu-media-input
              ref="media"
              v-model="category.media"
              :max-files="1"
              disable-video
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormCheckbox } from 'bootstrap-vue'
import pick from 'lodash/pick'
import { ValidationObserver } from 'vee-validate'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import ParentCategorySelect from '@/views/store/inputs/ParentCategorySelect'
import LocalizationService from '@/services/localization-service'

export default {
  name: 'CategoryGeneralForm',
  components: {
    BFormCheckbox,
    ParentCategorySelect,
    MyMenuMediaInput: () => import(/* webpackChunkName: "cloud-input" */ '@/views/media/MyMenuMediaInput'),
    OkTextInput,
    ValidationObserver,
    BRow,
    BCol,
  },
  props: {
    isSubcategroy: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      category: {},
      name: '',
    }
  },
  computed: {
    editMode() {
      return !!this.form?.id
    },
  },
  watch: {
    form() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.category = { ...this.form }
      this.name = LocalizationService.getTranslate(this.category, 'name', this.selectedLocale, true)
      this.category.description = LocalizationService.getTranslate(this.category, 'description', this.selectedLocale, true)
      this.category.sort = this.category?.sort ?? 0
      this.category.status = !this.editMode ? true : !!this.category?.status
    },
    setLocales() {
      const locale = LocalizationService.getTranslatedLocale(this.category, this.selectedLocale, true) || this.$i18n.locale
      const localeIndex = LocalizationService.getLocaleIndex(this.category, locale)

      const localeObject = { locale, name: this.name, description: this.category.description }
      if (!this.category?.locales) this.category.locales = []
      if (localeIndex < 0) {
        this.category.locales.push(localeObject)
        return
      }

      this.category.locales[localeIndex] = localeObject
      this.category.locales = [...this.category.locales]
    },
    prepareForSubmit() {
      const keys = ['parent_id', 'status', 'sort']
      const values = pick(this.category, keys)
      let images = []
      if (!this.isSubcategroy && this.$refs?.media) images = this.$refs.media.prepareForSubmit().images ?? []
      this.setLocales()
      const { locales } = this.category

      const payload = {
        ...values,
        parent_id: this.category.parent_id,
        locales,
      }
      if (!this.isSubcategroy) {
        if (!images.length) {
          payload.remove_image = 1
        } else if (images[0]) {
          // eslint-disable-next-line prefer-destructuring
          payload.image = images[0]
        }
      }
      return payload
    },
    async isValid() {
      return this.$refs.form.validate()
    },
  },
}
</script>

<style lang="scss">
.category-general-form {
  .parent-category-select {

     .vs__dropdown-toggle {
       max-height: 38px;

       .vs__selected {
         overflow: hidden;
         text-overflow: ellipsis;
         display: -webkit-box;
         -webkit-line-clamp: 1; /* number of lines to show */
         line-clamp: 1;
         -webkit-box-orient: vertical;
         white-space: initial;
       }
     }
  }
}
</style>
