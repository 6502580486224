<template>
  <categories-shared-table
    :config="config"
  />
</template>

<script>

import CategoriesSharedTable from '@/views/store/categories/CategoriesSharedTable'
import CategoriesTableConfig from './config/subcategories-table-config'

export default {
  components: {
    CategoriesSharedTable,
  },
  data() {
    return {
      config: CategoriesTableConfig,
    }
  },
}
</script>
